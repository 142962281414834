import * as _stimulus2 from "@hotwired/stimulus";
var _stimulus = "default" in _stimulus2 ? _stimulus2.default : _stimulus2;
var exports = {};
(function (e, t) {
  "object" < "u" ? exports = t(_stimulus) : (e = typeof globalThis < "u" ? globalThis : e || self, e.StimulusClipboard = t(e.Stimulus));
})(exports, function (e) {
  "use strict";

  class t extends e.Controller {
    connect() {
      this.hasButtonTarget && (this.originalContent = this.buttonTarget.innerHTML);
    }
    copy(i) {
      i.preventDefault();
      const s = this.sourceTarget.innerHTML || this.sourceTarget.value;
      navigator.clipboard.writeText(s).then(() => this.copied());
    }
    copied() {
      this.hasButtonTarget && (this.timeout && clearTimeout(this.timeout), this.buttonTarget.innerHTML = this.successContentValue, this.timeout = setTimeout(() => {
        this.buttonTarget.innerHTML = this.originalContent;
      }, this.successDurationValue));
    }
  }
  return t.targets = ["button", "source"], t.values = {
    successContent: String,
    successDuration: {
      type: Number,
      default: 2000
    }
  }, t;
});
export default exports;